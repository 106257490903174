import { Schedule } from './../models/schedule';
import { ScheduleService } from './../schedules/services/schedule.service';
import { subMonths } from 'date-fns';
import { UsageService } from './../usage/services/usage.service';
import { RebootService } from './../services/reboot.service';
import { HomeKitRegistration } from 'plugins/homekit-reg';
import { ErrorTitle, LoadingMessage, ErrorMessage, DialogTitle, DialogMessage, NotificationMessage, DialogButtonText } from './../../assets/strings';
import { SelectionModalComponent } from './../selection-modal/selection-modal.component';
import { Capacitor } from '@capacitor/core';
import { RebooterTypes } from './../models/rebooter-types';
import { IonicNotificationService } from './../services/ionic-notification.service';
import { Home } from 'app/models/home';
import { HomeService } from './../home/services/home.service';
import { Component, OnDestroy, OnInit, ViewChild, KeyValueDiffers, DoCheck, NgZone, AfterViewChecked } from '@angular/core'
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormControl } from '@angular/forms';

import { startWith, switchMap, takeUntil, filter, concatMap } from 'rxjs/operators';
import { interval, Subject, forkJoin, noop, zip, from, Observable, Subscription } from 'rxjs';

import { thingShadow } from 'aws-iot-device-sdk';

import { CSInWallOutlet, CSLampController, CSRebooter, CSSmartOutlet, Device, DeviceFeature, DeviceService, FirmwareService, Command } from '@connectsense/iot8020-library';

import { IonicDialogService } from '../services/ionic-dialog.service';
import { ShadowService } from '../services/shadow.service';
import { DebouncedFormGroup } from '../shared/forms/debounced-form-group';
import { IonAccordionGroup, ModalController, PickerController } from '@ionic/angular';
import { OutletTypes } from 'app/models/outlet-types';

@Component({
  moduleId: module.id,
  selector: 'app-device-detail',
  templateUrl: 'device-detail.component.html',
  styleUrls: ['device-detail.component.scss'],
  providers: [ UsageService ]
})
export class DeviceDetailComponent implements OnInit, OnDestroy, DoCheck, AfterViewChecked {
  @ViewChild('intelligentRebootAccordion') intelligentRebootAccordion: IonAccordionGroup;
  readonly DeviceFeature = DeviceFeature;
  LoadingMessage = LoadingMessage;
  device: CSInWallOutlet | CSSmartOutlet | CSRebooter | CSLampController;
  deviceId: string;
  errors = {
    notFound: false,
    server: false
  };
  firmwareUpdateAvailable: boolean;
  loading: boolean;
  ngUnsubscribe: Subject<void> = new Subject<void>();
  outlet: string;
  shadowsRegistered = false;
  shadows: thingShadow;
  homes: Home[] = [];
  RebooterTypes = RebooterTypes;

  deviceDataFormGroup: DebouncedFormGroup;
  rebooterAdvancedFormGroup: DebouncedFormGroup;

  platform = Capacitor.getPlatform();
  labelPosition = this.platform === 'ios' ? undefined : 'floating';
  itemLines = this.platform === 'ios' ? 'inset' : 'none';

  outletSelectionType = 'outlet';
  roomSelectionType = 'room';
  rebooterSelectionType = 'rebooter';

  isRebooting = false;
  differ: any;
  rebootRefresh: Subscription;

  lastRebootTimestamp;
  rebooterSchedule: Schedule;
  rebooterGlance: string;

  didSetAccordion = false;

  get deviceName(): string {
    if (!this.device) {
      return '';
    }

    const outletName = this.device.data[`${this.outlet}_name`];
    if (outletName) {
      return outletName;
    }

    return this.device.name || '';
  }

  get roomLabel(): string {
    let selectedRoomName: string;

    this.homes.forEach((home) => {
      return home.rooms?.forEach(room => {
        if (room.roomId === this.device.roomId) {
          return selectedRoomName = room.name;
        }
      });
    });
    return selectedRoomName;
  }

  get outletTypeLabel(): string {
    const selectedType = this.device.data[this.outlet + '_type'];
    return Object.values(OutletTypes).find(type => type.id === selectedType)?.label;
  }

  get rebooterTypeLabel(): string {
    const selectedType = this.device['config'].outlet1_type;
    return Object.values(RebooterTypes).find(type => type.outlet1_type === selectedType)?.label;
  }

  get canBeHomeKitRemoved(): boolean {
    return this.device.state['homekit_paired'] && Capacitor.getPlatform() === 'ios';
  }

  get isRebooterV2(): boolean {
    const numFirmwareVersion = parseFloat(this.device.state.mcu_firmware_version);
    return numFirmwareVersion >= 1 && this.device.isCSRebooter();
  }

  constructor(
    private deviceService: DeviceService,
    private firmwareService: FirmwareService,
    private ionicDialogService: IonicDialogService,
    private route: ActivatedRoute,
    public router: Router,
    private shadowService: ShadowService,
    private homeService: HomeService,
    private ionicNotificationService: IonicNotificationService,
    private modalController: ModalController,
    private rebootService: RebootService,
    private differs:  KeyValueDiffers,
    private usageService: UsageService,
    private scheduleService: ScheduleService,
    private ngZone: NgZone,
    private pickerController: PickerController,
  ) { this.differ = differs.find([]).create(); }

  checkForFirmwareUpdates() {
    this.firmwareService.checkForUpdate(this.device.deviceTypeId, this.device.deviceId, this.device['state'].mcu_firmware_version).pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(({ latestVersion, needsUpdate, updateAvailable }) => {
        this.firmwareUpdateAvailable = updateAvailable;
        if (needsUpdate) {
          this.ionicDialogService.generic(
            'Firmware update available',
            `Version ${latestVersion} is ready to be downloaded`
          );
        }
      });
  }

  loadDevice(): void {
    this.loading = true;

    this.route.params.pipe(
      switchMap((params: Params) => this.deviceService.getDevice(this.deviceId)))
      .subscribe(device => {
          this.setUpDevice(device);
        }, err => {
          const code = err.response?.status;
          this.errors.notFound = code === 404;
          this.errors.server = code === 500;
        }
      );
  }

  requestOutletScan(): void {
    interval(30000).pipe(
      startWith(0),
      takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        const topic = `iot8020/${this.deviceId}/command`;
        const payload = {
          jsonrpc: '2.0',
          id: Date.now(),
          method: 'scan',
          params: {
            rate: 5,
            duration: 25
          }
        };

        this.shadows.publish(topic, JSON.stringify(payload));
      });
  }

  setDeviceId() {
    this.deviceId = this.route.snapshot.params['deviceId'];
  }

  setDeviceShadow(): void {
    this.shadows = this.shadowService.shadows;

    if (this.device.hasFeature(DeviceFeature.RealtimePowerMonitoring)) {
      this.requestOutletScan();
    }
  }

  setOutlet(): void {
    this.route
      .queryParamMap
      .subscribe(params => {
        this.outlet = params.get('outlet');
      });
  }

  setUpDevice(device: Device): void {
    this.device = device;
    this.setUpForms(device);
    this.subscribeToLiveEvents();
    this.checkForFirmwareUpdates();
    this.subscribeToRebootEvents();
    this.getLastReboot();
    if (this.device.isCSRebooter()) {
      this.getSchedule();
    }
  }

  getHomes(): void {
    this.homeService.getHomes().subscribe(homes => {
      this.homes = homes;
    });
  }

  subscribeToRebootEvents() {
    this.rebootService.rebootingDevices.subscribe((deviceId) => {
      if (!deviceId) {
        return;
      }
      if (deviceId.includes('FAILED') && deviceId.includes(this.device.deviceId)) {
        this.isRebooting = false;
        this.rebootRefresh.unsubscribe();
      } else if (this.rebootService.rebootingDeviceIds.includes(this.device.deviceId)) {
        this.isRebooting = true;
        this.refreshLiveEventSubscription();
      }
    });
  }

  getLastReboot() {
    if (!this.device.isCSRebooter()) {
      this.loading = false;
      this.lastRebootTimestamp = undefined;
      return;
    }

    this.usageService.getRebootEvents(this.deviceId, subMonths(new Date(), 3)).subscribe(events => {
      events = this.usageService.readifyRebootEvents(events);
      this.ngZone.run(() => {
        this.lastRebootTimestamp = events[0]?.timestamp;
        this.loading = false;
      });
    });
  }

  getSchedule() {
    this.scheduleService.getSchedules(this.deviceId)
    .subscribe((schedules: Schedule[]) => {
      if (!schedules.length) {
        this.rebooterSchedule = undefined;
      }

      schedules.map((schedule: Schedule & { time: number }) => {
        const { minutes, hours } = this.scheduleService.parseCronExpression(schedule.schedule);
        schedule.time = new Date().setHours(hours, minutes);
        this.rebooterSchedule = schedule;
        return schedule;
      });

      if (this.rebooterSchedule) {
        this.setScheduleGlance(this.rebooterSchedule.schedule);
      }
    });
  }

  setScheduleGlance(schedule: string) {
    const parts = schedule.split(' ');
    const minute = parts[0];
    const hour = parts[1];
    const days = parts[4];

    let hourNum = parseInt(hour, 10);

    const ampm = hourNum >= 12 ? 'PM' : 'AM';

    hourNum = hourNum % 12;
    hourNum = hourNum ? hourNum : 12;

    const formattedTime = `${hourNum}:${minute} ${ampm}`;

    const dayNumbers = days.split(',').map(Number);

    const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const specialCases = {
      '1,2,3,4,5': 'weekdays',
      '0,6': 'weekends',
      '0,1,2,3,4,5,6': 'every day'
    };

    const daysKey = dayNumbers.sort((a, b) => a - b).join(',');
    if (specialCases[daysKey]) {
      return this.rebooterGlance = `Reboot ${specialCases[daysKey]} at ${formattedTime}`;
    }

    const readableDays = dayNumbers.map(dayNum => dayNames[dayNum]).join(', ');

    return this.rebooterGlance = dayNumbers.length === 1 ?
      `Reboot ${readableDays}s at ${formattedTime}` :
      `Reboot multiple days at ${formattedTime}`;
  }

  setUpForms(device: Device): void {
    this.deviceDataFormGroup = new DebouncedFormGroup({
      [`${this.outlet}_name`]: new FormControl(device.data[`${this.outlet}_name`]),
      [`${this.outlet}_type`]: new FormControl(device.data[`${this.outlet}_type`]),
      ['deviceName']: new FormControl(device.name),
      ['room']: new FormControl(device.roomId),
    });
    this.watchDeviceDataGroupChanges();
    if (this.isRebooterV2) {
      const rebooter = device as CSRebooter;
      this.rebooterAdvancedFormGroup = new DebouncedFormGroup({
        ['outlet1_type']: new FormControl(rebooter.config.outlet1_type),
        ['auto_reset']: new FormControl(rebooter.config.auto_reset),
        ['off_duration']: new FormControl(rebooter.config.off_duration),
        ['outage_trigger_time']: new FormControl(rebooter.config.outage_trigger_time),
        ['detection_delay']: new FormControl(rebooter.config.detection_delay),
        ['max_reboots']: new FormControl(rebooter.config.max_reboots),
        ['target_addrs']: new FormControl(rebooter.config.target_addrs),
        ['any_fail_logic']: new FormControl(rebooter.config.any_fail_logic),
      });

    } else {
      this.rebooterAdvancedFormGroup = new DebouncedFormGroup({
        ['outlet1_type']: new FormControl(device['config']?.outlet1_type),
        ['auto_reset']: new FormControl(device['config']?.auto_reset),
        ['detection_time']: new FormControl(device['config']?.detection_time),
        ['outlet1_on_delay']: new FormControl(device['config']?.outlet1_on_delay),
      });
    }

    this.watchRebooterAdvancedGroupChanges();
  }

  subscribeToLiveEvents = () => {
    if (!this.device) {
      return;
    }

    this.shadowService.watch([this.device]).subscribe(() => {
      this.setDeviceShadow();
      if (this.intelligentRebootAccordion) {
        this.intelligentRebootAccordion.value = this.device['config']?.auto_reset ? 'first' : undefined;
      }
    });
  };

  refreshLiveEventSubscription() {
    this.rebootRefresh = Observable.timer(5000, 10000)
    .takeWhile(() => this.isRebooting)
      .subscribe(() => {
        this.shadowService.unsubscribe();
        this.subscribeToLiveEvents();
      });
  }

  checkIntelligentReboot() {
    if (this.intelligentRebootAccordion && !this.didSetAccordion) {
      this.intelligentRebootAccordion.value = this.device['config']?.auto_reset ? 'first' : undefined;
      this.didSetAccordion = true;
    }
  }

  watchDeviceDataGroupChanges(): void {
    this.deviceDataFormGroup.debouncedValueChanges.pipe(
      switchMap(data => {
        const outletNameKey = `${this.outlet}_name`;
        const outletTypeKey = `${this.outlet}_type`;

        const outletData = {
          [outletNameKey]: data[outletNameKey],
          [outletTypeKey]: data[outletTypeKey]
        };

        this.device.name = data.deviceName;
        this.device.roomId = data.room;

        return forkJoin(
          this.deviceService.updateDeviceData(this.deviceId, outletData),
          this.deviceService.updateDevice(this.device),
          this.homeService.changeDeviceRoom(this.deviceId, this.device.roomId)
        )
      }),
      takeUntil(this.ngUnsubscribe)
    ).subscribe();
  }

  watchRebooterAdvancedGroupChanges(): void {
    this.rebooterAdvancedFormGroup.debouncedValueChanges.pipe(
      switchMap(config => {
        if (this.isRebooterV2) {
          this.device['config'].auto_reset = config.auto_reset;
          this.device['config'].outlet1_type = config.outlet1_type;
          this.device['config'].off_duration = config.off_duration;
          this.device['config'].outage_trigger_time = config.outage_trigger_time;
          this.device['config'].detection_delay = config.detection_delay;
          this.device['config'].max_reboots = config.max_reboots;
          this.device['config'].target_addrs = config.target_addrs;
          this.device['config'].any_fail_logic = config.any_fail_logic;
        } else {
          this.device['config'].auto_reset = config.auto_reset;
          this.device['config'].detection_time = config.detection_time;
          this.device['config'].outlet1_on_delay = config.outlet1_on_delay;
          this.device['config'].outlet1_type = config.outlet1_type;
        }

        return this.deviceService.updateDeviceConfig(this.deviceId, config);
      }),
      takeUntil(this.ngUnsubscribe)
    ).subscribe();
  }

  toggleRebootAccordion() {
    // Logic is backwards - to grab the toggle before the debounce
    this.intelligentRebootAccordion.value = this.device['config'].auto_reset ? undefined : 'first';
  }

  selectType(selectedType: number) {
    const rebooterType = RebooterTypes.find((type) => {
      return type.outlet1_type === selectedType;
    });

    if (this.isRebooterV2) {
      this.device['config'].outage_trigger_time = rebooterType.outage_trigger_time;
      this.rebooterAdvancedFormGroup.controls.outage_trigger_time.setValue(this.device['config'].outage_trigger_time);
    } else {
      this.device['config'].outlet1_on_delay = rebooterType.outlet1_on_delay;
      this.rebooterAdvancedFormGroup.controls.outlet1_on_delay.setValue(this.device['config'].outlet1_on_delay);
    }
  }

  onBrightnessChange(value: number, deviceId: string) {
    this.deviceService.executeCommand(deviceId, Command.SetBrightness, value)
    .subscribe(noop, error => {
      console.error(error);
      this.ionicDialogService.generic(ErrorTitle.Error, ErrorMessage.BrightnessChangeFail);
    });
  }

  onButtonEnabledChange(checked: boolean, deviceId: string) {
    const params = { lock: checked };

    this.deviceService.executeTopicCommand(deviceId, Command.SetButtonEnabled, params, this.shadows);
  }

  getSerialNumber(deviceId: string): string {
    return deviceId.split('_')[1].replace('CS-SO-2-', '').replace('CS-IWO-', '');
  }

  removeDevice() {
    this.ionicDialogService.confirm(
      DialogTitle.DeviceDelete,
      DialogMessage.DeviceDelete,
      [{
        text: DialogButtonText.Cancel,
        role: 'cancel'
      }, {
        text: DialogButtonText.Remove,
        role: 'confirm',
        cssClass: 'remove-button'
      }]
    ).pipe(
      filter((userDidConfirm: boolean) => userDidConfirm === true),
      concatMap(() => this.ionicDialogService.loading(LoadingMessage.DeviceDelete)),
      switchMap(() => {
        if (this.canBeHomeKitRemoved) {
          return zip(
            this.deviceService.removeDevice(this.device.deviceId),
            from(HomeKitRegistration.removeHomeKitDevice({deviceId: this.getSerialNumber(this.device.deviceId)}))
          )
        } else {
          return this.deviceService.removeDevice(this.device.deviceId)
        }
      })
    ).subscribe((deviceRemoved) => {
      if (deviceRemoved) {
        this.ionicDialogService.dismissLoading();
        this.ionicNotificationService.show(NotificationMessage.DeviceRemoved);
        this.router.navigate(['/devices']);
      } else {
        this.handleDeviceRemovalError(new Error(ErrorMessage.DeleteDeviceFail));
      }
    }, (error) => {
      this.handleDeviceRemovalError(new Error(ErrorMessage.DeleteDeviceFail));
      console.error('An error occurred', error);
    });
  }

  handleDeviceRemovalError(error: Error) {
    this.ionicDialogService.dismissLoading();
    const errorMessage = error.message || error;
    this.ionicDialogService.generic(ErrorTitle.DeleteDeviceFail, errorMessage.toString());
  }

  async openSelectionModal(title: string, selectorType: string, value: any, arrayName?: string) {
    const modal = await this.modalController.create({
      component: SelectionModalComponent,
      componentProps: {
        ['title']: title,
        ['selectorType']: selectorType,
        ['value']: value,
        ['array']: this[arrayName]
      }
    });

    modal.present();
    const { data, role } = await modal.onWillDismiss();

    if (this.device.isCSRebooter() && selectorType === this.rebooterSelectionType) {
      this.selectType(data);
    }
    this.setSelectionData(selectorType, data);
  }

  setSelectionData(selectorType: string, modalData: any) {
    switch (selectorType) {
      case this.outletSelectionType:
        this.device.data[`${this.outlet}_type`] = modalData;
        this.deviceDataFormGroup.controls[`${this.outlet}_type`].setValue(this.device.data[`${this.outlet}_type`]);
        break;
      case this.roomSelectionType:
        this.device.roomId = modalData;
        this.deviceDataFormGroup.controls.room.setValue(this.device.roomId);
        break;
        case this.rebooterSelectionType:
          this.device['config'].outlet1_type = modalData;
          this.rebooterAdvancedFormGroup.controls['outlet1_type'].setValue(modalData);
          break;
    }
  }

  async openMaxRebootModal(event) {
    event.preventDefault();
    event.stopPropagation();

    const pickerOptions = [
      { text: 'No limit', value: 0 },
      { text: '1', value: 1 },
      { text: '2', value: 2 },
      { text: '3', value: 3 },
      { text: '4', value: 4 },
      { text: '5', value: 5 },
      { text: '6', value: 6 },
      { text: '7', value: 7 },
      { text: '8', value: 8 },
      { text: '9', value: 9 },
      { text: '10', value: 10 }
    ];

    const currentValue = this.rebooterAdvancedFormGroup.controls.max_reboots.value;
    const selectedIndex = pickerOptions.findIndex(option => option.value === currentValue);

    const picker = await this.pickerController.create({
      columns: [
        {
          name: 'reboots',
          selectedIndex: selectedIndex,
          options: pickerOptions
        },
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Confirm',
          handler: (selection) => {
            this.rebooterAdvancedFormGroup.controls.max_reboots.setValue(selection.reboots.value);
          },
        },
      ],
    });

    await picker.present();
  }

  ngOnInit(): void {
    this.setDeviceId();
    this.setOutlet();
    this.loadDevice();
    this.getHomes();
  }

  ngAfterViewChecked(): void {
    this.checkIntelligentReboot();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.shadowService.unsubscribe();
    if (this.rebootRefresh) {
      this.rebootRefresh.unsubscribe();
    }
  }

  ngDoCheck(): void {
    if (!this.device) {
      return;
    }
    if (!this.device.isCSRebooter() || !this.rebootService.rebootingDeviceIds.includes(this.device.deviceId)) {
      return;
    }
    const changes = this.differ.diff(this.device);
    if (changes) {
      changes.forEachChangedItem((change) => {
        if (change.key === 'data' && !change.currentValue.offline) {
          this.isRebooting = false;
          if (this.rebootRefresh) {
            this.rebootRefresh.unsubscribe();
          }
          this.ionicNotificationService.show(NotificationMessage.RebootFinish);
        }
      });
    }
  }
}
