export enum DialogButtonText {
  AccountDelete = 'Delete Account',
  Cancel = 'Cancel',
  Continue = 'Continue',
  HomeKitOpenSettings = 'Open Settings',
  IntegrationInstructions = 'Linking Instructions',
  No = 'No',
  OK = 'OK',
  Reboot = 'Reboot',
  Remove = 'Remove',
  ResetPassword = 'Reset',
  Yes = 'Yes',
}

export enum DialogTitle {
  AccountDelete = 'This will permanently delete your account!',
  AddToHomeKit = 'Add to HomeKit',
  AllowHomeKit = 'Allow HomeKit Access',
  ConfirmFirmwareUpdate = 'Confirm firmware update',
  DeviceNameHelp = 'Device name',
  DeviceDelete = 'Remove device?',
  Help = 'Help',
  HomeDelete = 'Confirm home deletion',
  RebootManual = 'Reboot now',
  ResetLink = 'Send reset link?',
  ScheduleDelete = 'Delete schedule?',
  SessionExpired = 'Session expired',
  SetupCancel = 'Cancel setup?',
  SetupPollingPrompt = 'Setup is taking a while...',
  Success = 'Success',
}

export enum DialogMessage {
  AccountDelete = `
  <p>Are you sure you want to continue?</p>
  <p>You will no longer be able to control any ConnectSense devices unless you create a new account and set them up again.</p>
  `,
  AddToHomeKit = 'Use this ConnectSense device with Apple\'s Home app and other HomeKit apps. You must be near your device to complete this step.',
  AllowHomeKit = 'In order to add your device to HomeKit, you need to allow permissions. Tap the button below to allow access from settings.',
  ConfirmFirmwareUpdate = 'Are you sure you want to continue? Update could take up to 10 minutes',
  DeviceNameHelp = 'This name covers your entire device, not a single outlet on the device. To change the name of an outlet, see the Outlet Settings tab.',
  DeleteSuccess = 'Your account has been deleted.',
  DeviceDelete = `
  <p>
  This will reset the device, remove it from the network, cancel its schedules, and delete any usage history.
  </p>
  <p>You can re-add the device at a later time.</p>
  `,
  HomeDelete = 'Are you sure you want to delete this home?',
  RebootManual = `
  <p>Rebooting this device will temporarily interrupt power to the connected device.</p>
  <p>Rebooting a router will likely interrupt the Internet connection to all devices in your home or office. The app may appear unresponsive for a few minutes.</p>
  `,
  ResetLink = 'This will send a password reset link to',
  ResetSuccess = 'Check your email for a password reset link',
  ScheduleDelete = 'Deleting a schedule is permanent and can\'t be undone',
  SessionExpired = 'Unfortunately your setup session has expired and you will need to start again',
  SetupCancel = 'All of your progress will be lost',
  SetupPollingPrompt = 'Did both top lights on your outlet stop blinking?',
  SetupPollingPromptRebooter = 'Did the light on your rebooter stop blinking?'
}

export enum ErrorTitle {
  CredentialsFetchFail = 'Account Error',
  DeleteDeviceFail = 'Removal Error',
  Error = 'Error',
  HomeKitDeviceUnreachable = 'Device Offline',
  IntegrationOpenFail = 'Can\'t open app',
  SetupAutomaticConnectFail = 'Automatic Connection Error',
  TimeOut = 'Timed Out',
}

export enum ErrorMessage {
  AccountDeleteFail = 'There was a problem deleting your account. Please contact customer service.',
  BleFail = 'Could not connect to your device',
  BleTimeOut = 'Could not connect to your device. Please make sure the device is in pairing mode.',
  BrightnessChangeFail = 'There was a problem updating brightness',
  ClaimCodeFetchFail = 'There was a problem fetching your account',
  CredentialsFetchFail = 'Could not verify your account. Please sign back in.',
  DeleteDeviceFail = 'Failed to remove device',
  FirmwareUpdateFail = 'There was a problem updating the firmware',
  GenericError = 'There was a problem',
  GenericTryAgain = 'There was a problem. Please try again',
  HomeFetchFail = 'There was a problem getting your homes',
  HomeKitDeviceUnreachable = 'Please make sure your device is plugged in and online before starting registration.',
  HomeKitResultFail = 'Device not found',
  NetworkScanFail = 'Could not fetch networks',
  OutletToggleFail = 'There was a problem updating your outlet',
  ProvisioningStatusReadFail = 'Received unexpected data from device, please try again.',
  RegistrationTimeout = 'It\'s taking too long to connect your device to the Internet. Please try again.',
  RegistryError = 'Could not find your device. Please check your internet connection and try again.',
  RoomCreateFail = 'There was a problem creating the room',
  RoomFetchFail = 'There was a problem loading the room',
  RoomListFetchFail = 'There was a problem loading your rooms',
  RoomUpdateFail = 'There was a problem updating the room',
  ScheduleCreateFail = 'There was problem creating the schedule',
  ScheduleDeleteFail = 'There was a problem deleting the schedule',
  ScheduleFetchFail = 'There was a problem getting your schedule',
  ScheduleInvalid = 'At least one day is required',
  ScheduleLimitFail = 'You\'ve reached the maximum number of schedules for the highlighted device',
  ScheduleLoadFail = 'There was a problem loading your schedules',
  ScheduleUpdateFail = 'There was a problem updating the schedule',
  SetupAutomaticConnectFail = 'Could not connect to your device. Please make sure the device is on and ready to pair.',
  SetupNetworkConnectFail = 'The device could not connect to the network. Please try again.',
  SetupTimeout = 'The setup process failed, please try again',
  TimeOut = 'It\'s taking too long to connect to your device. Please try again.',
  VerifyTimeout = 'Timed out trying to verify your device',
}

export enum LoadingMessage {
  AccountDelete = 'Deleting account',
  BleDeviceFound = 'Found your device!',
  Cancel = 'Canceling',
  ConnectedToDevice = 'Connected to device',
  ConnectedToNetwork = 'Connected back to network',
  ConnectingToDevice = 'Connecting to your device',
  Detail = 'Loading device details',
  DeviceDelete = 'Removing device',
  Devices = 'Loading devices',
  FinishReboot = 'Reboot complete',
  GetClaimCode = 'Fetching account',
  Home = 'Loading home',
  Homes = 'Loading homes',
  Login = 'Signing in',
  Rebooting = 'Rebooting...',
  Rooms = 'Loading rooms',
  Schedules = 'Loading schedules',
  Schedule = 'Loading schedule',
  SavingSchedule = 'Saving schedule',
  SavingOutlet = 'Saving settings',
  SavingUsage = 'Saving',
  ScanningForNetworks = 'Scanning for nearby networks',
  SendingCredentials = 'Sending credentials to device...',
  StartFirmwareUpdate = 'Updating firmware. This may take a few minutes.',
  StartHomeKit = 'Preparing to add device...',
  StartProvisioning = 'Preparing to add device...',
  StartReboot = 'Rebooting...',
  Usage = 'Loading usage history',
  VerifyConnection = 'Verifying connection',
  VerifyConnectivity = 'Verifying connectivity',
  VerifyDevice = 'Verifying your new device...',
  EmptyMessage = '',

}

export enum NotificationMessage {
  AppUpdate = 'App Update Available',
  AppUpdateOption = 'RELOAD',
  DeviceUpdated = 'Device updated',
  DeviceRemoved = 'Device removed',
  GenericError = 'Something went wrong',
  OutletUpdated = 'Outlet updated',
  RebootFinish = 'Reboot complete',
}

export enum ScreenTitle {
  BlufiConnect = 'Connect to Device',
  BlufiConnecting = 'Connecting',
  BlufiConnectingDevice = 'Connecting Device',
  BlufiNetwork = 'Provide a Network',
  BlufiReady = 'Ready Device',
  BlufiSetup = 'Device Setup',
  HomeKitAccess = 'HomeKit Access',
  HomeKitConnect = 'HomeKit Setup',
  HomeKitReady = 'Ready Device',
  HomeKitRegistering = 'Registering',
  HomeKitSetup = 'HomeKit Setup',
  SetupConnect = 'Connect to Device',
  SetupConnectQR = 'Scan QR',
  SetupConnecting = 'Connecting Device',
  SetupNetwork = 'Provide a Network',
  SetupWifi = 'Change Your WiFi',
  Success = 'Success',
}
