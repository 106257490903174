<ng-container *ngIf="devices.length === 1 && devices[0].isCSLampController(); else multiple">
  <app-device-list-item
  [device]="devices[0]"
  (outletSelected)="outletSelected($event)"
  [selectable]="false"
  [selected]="selected[devices[0].deviceId]"
  [class.error]="devices[0].deviceId === errorDeviceId"
  [shouldExecuteCommand]="false"
  ></app-device-list-item>
</ng-container>
<ng-template #multiple>
  <ion-list-header>
    <ion-label>{{rebootersSchedules?.length > 0 ? "Select Device" : "Device Actions"}}</ion-label>
  </ion-list-header>
  <app-device-list-item
  *ngFor="let device of devices | filterGroupedDevices; let first = first; let last = last;"
  [device]="device"
  (outletSelected)="outletSelected($event)"
  [selectable]="true"
  [selected]="selected[device.deviceId]"
  [class.error]="device.deviceId === errorDeviceId"
  [shouldExecuteCommand]="false"
  [manualLines]="(first && !last) || (!first && !last) ? true : false"
  [checkboxEnabled]="rebootersSchedules?.length > 0 ? getScheduleDevice(device).enabled : (device.isConnected() ? true : false)"
  [labelDisabled]="rebootersSchedules?.length > 0 ? !getScheduleDevice(device).enabled : (device.isConnected() ? false : true)"
  ></app-device-list-item>
  <ng-container *ngFor="let home of homes | filterEmptyHomes; let first = first; let last = last;">
    <ng-container *ngFor="let room of home.rooms | filterEmptyRooms;">
      <ion-item-divider class="schedule-device-homes">
        <ng-container *ngIf="first && last; else multipleHomes">
          <ion-label>{{room.name}}</ion-label>
        </ng-container>
        <ng-template #multipleHomes>
          <ion-label>{{home.name}} - {{room.name}}</ion-label>
        </ng-template>
      </ion-item-divider>
      <app-device-list-item
        *ngFor="let device of room.devices; let first = first; let last = last;"
        [device]="device"
        (outletSelected)="outletSelected($event)"
        [selectable]="true"
        [selected]="selected[device.deviceId]"
        [class.error]="device.deviceId === errorDeviceId"
        [shouldExecuteCommand]="false"
        [manualLines]="(first && !last) || (!first && !last) ? true : false"
        [checkboxEnabled]="rebootersSchedules?.length > 0 ? getScheduleDevice(device).enabled : true"
        [labelDisabled]="rebootersSchedules?.length > 0 ? !getScheduleDevice(device).enabled : true"
      ></app-device-list-item>
    </ng-container>
  </ng-container>
</ng-template>
